import React from 'react';
import '@/styles/globals.css';
import { useApollo } from '@/lib/apolloClient';
import { ApolloProvider } from '@apollo/client';
import ErrorBoundary from '@/components/shared/ErrorBoundary';
import { DefaultLayout } from '@/components/shared/Layout';
import { AppPropsWithLayout } from '@/interfaces/common';
import { TopProgressBar } from '@/components/shared';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'rc-dropdown/assets/index.css';
import Script from 'next/script';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import { avenir, montserrat, poppins } from '@/assets/fonts';

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const apolloClient = useApollo(pageProps);
  const Layout = Component.Layout ?? DefaultLayout;

  return (
    <>
      <Head>
        <link
          rel="alternate"
          href={typeof window !== 'undefined' ? window.location.href : ''}
          hrefLang="vi-vn"
        />
      </Head>
      <Script
        defer
        id="googletagmanager"
        src="https://www.googletagmanager.com/gtag/js?id=G-FH050W80WG"
      />
      <div
        className={`${poppins.variable} ${avenir.variable} ${montserrat.variable} font-sans`}
      >
        <ApolloProvider client={apolloClient}>
          <ErrorBoundary>
            <Layout>
              <TopProgressBar />
              <Component {...pageProps} />
              <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                  // Define default options
                  className: '',
                  duration: 5000,
                  style: {
                    background: '#363636',
                    color: '#fff',
                  },

                  // Default options for specific types
                  success: {
                    duration: 3000,
                  },
                }}
              />
            </Layout>
          </ErrorBoundary>
        </ApolloProvider>
      </div>
    </>
  );
}
